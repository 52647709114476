import React from 'react'
import { Badge, Stack } from 'react-bootstrap'

function Skills() {
  const skills = [
    'Java',
    'Javascript',
    'HTML',
    'CSS',
    'Docker',
    'Kubernetes',
    'Adobe Experience Manager',
    'ReactJs',
    'MySQL',
    'Adobe App Builder',
    'Adobe Target',
    'Nodejs',
    'OSGi',
    'Apache Sling',
    'Maven',
    'Apache Web Server',
    'Amazon Web Services',
  ]

  return (
    <section id='skills' className='py-5'>
      <h3 className='text-center mb-4'>Professional Skills</h3>
      <Stack direction='horizontal' gap={3}>
        {skills.map((skill, index) => (
          <span key={index} className='h2 p-2'>
            <Badge pill bg='secondary' className='mx-2 py-2'>
              {skill}
            </Badge>
          </span>
        ))}
      </Stack>
    </section>
  )
}

export default Skills
