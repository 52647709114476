import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
  import { Navbar, Container } from 'react-bootstrap'

const Navigation = () => {
  return (
    <Navbar
      bg={'dark'}
      data-bs-theme='dark'
      expand='lg'
      className='bg-body-tertiary justify-content-between'
    >
      <Container fluid>
        <Navbar.Brand href='/#' className='text-center'>
          <img
            alt='logo'
            src={ process.env.PUBLIC_URL + '/assets/images/logo.jpeg' }
            width='75px'
            height='75px'
            style={{ borderRadius: '50%' }}
            className='d-inline-block align-top'
          />{' '}
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}

export default Navigation
