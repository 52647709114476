import React, { Fragment } from 'react'

const About = () => {
  return (
    <Fragment>
      <h3>About Me: Nithin Dasyam</h3>
      <div className={'d-inline text-justify p-2 bd-highlight'}>
        <p className={'p-2 bd-highlight'}>
          Greetings! I'm Nithin Dasyam, an enthusiastic individual originally
          hailing from the vibrant city of Hyderabad, India. However, I've
          currently set my roots in the bustling metropolis of Dallas, Texas.
        </p>
        <p className={'p-2 bd-highlight'}>
          With a strong academic foundation, I hold a Master's degree in
          Engineering from the esteemed University of South Florida. I've been
          fortunate to translate my educational prowess into a fulfilling career
          journey, currently donning the hat of a Technical Consultant at Adobe.
          Working in such a dynamic environment allows me to continuously evolve
          and innovate, and I'm particularly proud of achieving certification in
          Adobe Experience Manager (AEM), a testament to my dedication and
          expertise in the field.
        </p>
        <p className={'p-2 bd-highlight'}>
          Beyond my professional pursuits, I thrive on an active lifestyle.
          Sports have always been a passion of mine, and you'll often find me
          engaging in intense matches of Pickleball and Basketball. These
          activities not only keep me physically fit but also foster camaraderie
          and healthy competition. When it's time to unwind, I have a soft spot
          for movies; there's nothing quite like immersing myself in a
          captivating storyline or getting lost in the world of cinema.
        </p>
        <p className={'p-2 bd-highlight'}>
          One of my notable accomplishments is becoming a certified expert in
          AEM. This achievement reflects my commitment to staying on the cutting
          edge of technology and my desire to provide top-notch solutions for
          clients. I believe in the power of continuous learning, and each
          project I undertake is an opportunity to further enhance my skills.
        </p>
        <p className={'p-2 bd-highlight'}>
          As I embrace the journey of life, I hold dear the values of diligence,
          integrity, and innovation. A famous quote that resonates with me is,
          "The only way to do great work is to love what you do" by Steve Jobs.
          This motto encapsulates my approach to both my professional and
          personal endeavors.
        </p>
        <p className={'p-2 bd-highlight'}>
          On the horizon, I look forward to connecting with like-minded
          individuals and contributing positively to the tech community. With a
          thirst for adventure, I've had the privilege of traversing different
          parts of the world, and each travel experience has enriched my
          perspective and taught me valuable life lessons.
        </p>
        <p className={'p-2 bd-highlight'}>
          Thank you for stopping by and getting to know a bit about me. Whether
          you share my passion for technology, sports, or simply exploring the
          wonders of life, I'm excited to connect and share experiences. Feel
          free to reach out—I'd love to hear from you!
        </p>
      </div>
    </Fragment>
  )
}

export default About
