/*
 * <license header>
 */

import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Hero from '../Hero/Hero'
import { Container } from 'react-bootstrap'
import SiteTabs from '../SiteTabs/SiteTabs'
import ProfileContext from '../Profile/Profile'

function App() {
  // Define a state to store the profile information object
  let profileInfo = null
  // Function to make a GET request to retrieve the profile information object
  /*const targetUrl = 'https://api.linkedin.com/v2/userinfo'
  const getProfileInfo = async () => {
    try {
      axios
        .get(targetUrl, {
          headers: {
            'Authorization': `Bearer ${LINKED_API_KEY}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
        })
        .then(response => {
          profileInfo = response.data
        })
        .catch(error => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }
  // Call the getProfileInfo function when the component mounts
  useEffect(() => {
    getProfileInfo()
  })*/

  return (
    <ProfileContext.Provider value={profileInfo}>
      <Header />
      <Hero />
      <Container fluid={'sm'}>
        <SiteTabs />
      </Container>
      <Footer />
    </ProfileContext.Provider>
  )
}

export default App
