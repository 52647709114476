import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Projects from '../Projects/Projects'
import Skills from '../Skills/Skills'
import About from '../About/About'
import { useState } from 'react'
import ContactSection from '../ContactSection/ContactSection'
import BlogSection from '../BlogSection/BlogSection'
import CertificationsSection from '../CertificationsSection/CertificationsSection'

const SiteTabs = () => {
  const [key, setKey] = useState('about')

  const updateKey = newKey => {
    setKey(newKey)
  }

  return (
    <Tabs activeKey={key} onSelect={updateKey} className='mb-3'>
      <Tab eventKey='about' title='About'>
        <About />
      </Tab>
      <Tab eventKey='experience' title='Experience'>
        <Projects />
      </Tab>
      <Tab eventKey='skills' title='Skills'>
        <Skills />
      </Tab>
      <Tab eventKey='certifications' title='Certifications'>
        <CertificationsSection />
      </Tab>
      <Tab eventKey='blogs' title='Blogs'>
        <BlogSection />
      </Tab>
      <Tab eventKey='contact' title='Contact'>
        <ContactSection />
      </Tab>
    </Tabs>
  )
}
export default SiteTabs
