import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './CertificationsSection.css'

const certifications = [
  {
    title: 'Adobe Certified Master - Adobe Experience Manager Sites Architect',
    image:
      'https://images.credly.com/size/680x680/images/f8bb518f-e604-4531-83f3-52a47ba5b72d/Adobe_Certified_Master_Experience_Cloud_products_Digital_Badge.png',
    link: 'https://www.credly.com/badges/3d1996ad-9108-4e11-aef3-80389be35cb4',
  },
  {
    title:
      'Adobe Certified Expert - Adobe Experience Manager Sites Lead Developer',
    image:
      'https://images.credly.com/size/680x680/images/3a78e715-3ded-4b43-9ba3-52710c06327a/Adobe_Certified_Expert_Experience_Cloud_products_Digital_Badge.png',
    link: 'https://www.credly.com/badges/da79ba21-bbcc-4982-9473-40eb6e75d816',
  },
]

function CertificationsSection() {
  return (
    <section id='certifications' className='bg-light py-3'>
      <Container fluid>
        <h2 className='text-center mb-4'>Certifications</h2>
        <Row>
          {certifications.map((certification, index) => (
            <Col key={index} xs={6} md={4}>
              <Card className='mb-4'>
                <Card.Img
                  className={'rounded mx-auto d-block certification-image'}
                  variant='top'
                  src={certification.image}
                  alt={certification.title}
                />
                <Card.Body>
                  <Card.Title>{certification.title}</Card.Title>
                  <a href={certification.link} className='btn btn-primary'>
                    View Certification
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default CertificationsSection
