import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import blogPosts from './blogs.json'

function BlogSection() {
  return (
    <section id='blogs' className='py-5'>
      <Container>
        <h2 className='text-center mb-4'>Latest Blog Posts</h2>
        <Row>
          {blogPosts.map((post, index) => (
            <Col key={index} md={4}>
              <Card className='mb-4'>
                <Card.Img variant='top' src={post.image} alt={post.title} />
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <p className={'font-weight-light'}>{post.date}</p>
                  <a href={post.link} className='btn btn-primary'>
                    Read More
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default BlogSection
