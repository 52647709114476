import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Hero.css'
import { BsArrowDownCircleFill } from 'react-icons/bs'
import { Container } from 'react-bootstrap'

const Hero = () => {
  const handleScroll = () => {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
  }
  return (
    <Container fluid className={'hero-container'}>
      <div className='d-flex min-vh-100 mb-5 hero' lc-helper='background'>
        <div className='align-self-center text-center text-light col-md-8 offset-md-2'>
          <div className='lc-block mb-4'>
            <div editable='rich'>
              <h1 className='display-1 fw-bolder'>Nithin Dasyam</h1>
            </div>
          </div>

          <blockquote className='blockquote text-center'>
            <p className='mb-0'>
              It is obvious that putting little white dots on the blue-black is
              not enough to paint a starry sky.
            </p>
            <footer className={'blockquote-footer'}>
              <cite title='Source Title'>Vincent van Gogh</cite>
            </footer>
          </blockquote>

          <div className='lc-block'>
            <BsArrowDownCircleFill className={'arrow-down-circle'} onClick={handleScroll}/>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Hero
