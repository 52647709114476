import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  BsFacebook,
  BsGithub,
  BsTwitter,
  BsLinkedin
} from 'react-icons/bs'
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.css'

const Footer = () => {
  return (
    <footer className='footer bg-dark text-white p-3 d-flex justify-content-between align-items-center'>
      <Container fluid={'md'}>
        <Row>
          <Col>
            <div>
              <p className='mb-0'>
                © 2023 Nithin Dasyam. All rights reserved.
              </p>
            </div>
          </Col>
          <Col className={'text-right'}>
            <div>
              <a
                href='https://www.facebook.com/nithin.dasyam/'
                target='_blank'
                rel='noopener noreferrer'
                className='social-link'
              >
                <BsFacebook />
              </a>
              <a
                href='https://twitter.com/nithin_dasyam'
                target='_blank'
                rel='noopener noreferrer'
                className='social-link'
              >
                <BsTwitter />
              </a>
              <a
                href='https://www.linkedin.com/in/nithin-dasyam'
                target='_blank'
                rel='noopener noreferrer'
                className='social-link'
              >
                <BsLinkedin />
              </a>
              <a
                href='https://github.com/nithindasyam'
                target='_blank'
                rel='noopener noreferrer'
                className='social-link'
              >
                <BsGithub />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
