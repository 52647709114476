import React, { useState, Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, Row, Col } from 'react-bootstrap'
import projects from './projects.json'
import './Projects.css'

const Projects = () => {
  return (
    <Fragment>
      <h3 className={'mt-lg-1'}>Work Experience</h3>
      <Row xs={1} md={2} className='g-4'>
        {projects.map(project => (
          <Col key={ project.id } xs={6} md={4} className='shadow-sm'>
            <Card border='light'>
              <Card.Img
                className='project-image'
                variant='top'
                src={ project.img_url }
              />
              <Card.Body>
                <Card.Title>{ project.name }</Card.Title>
                <Card.Text>
                  <ReadMore description={ project.description } />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Fragment>
  )
}

const ReadMore = ({ description }) => {
  const text = description
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <Fragment>
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className='read-or-hide'>
        {isReadMore ? '...read more' : ' show less'}
      </span>
    </Fragment>
  )
}

export default Projects
