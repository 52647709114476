import React, { useState } from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import dynamoDb from '../../utils/DynamodbClient'
import { v4 as uuidv4 } from 'uuid';

const ContactSection = () => {
  const initialFormData = {
    name: '',
    email: '',
    source: 'Google Search',
    comments: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const params = {
      TableName: "dasyam-site-source-contacts-table",
      Item: {
        id: uuidv4(),
        ...formData
      }
    };

    try {
      await dynamoDb.put(params).promise();
      console.log("Data saved successfully!");
      resetForm();
    } catch (error) {
      console.error("Error saving data to DynamoDB", error);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  return (
    <section id='contact' className='bg-light py-5'>
      <Container>
        <h2 className='text-center mb-4'>Contact Me</h2>
        <Row>
          <Col md={6}>
            <Card className='p-4'>
              <h4>Contact Information</h4>
              <p>
                Feel free to get in touch with me using the information below:
              </p>
              <address>
                <strong>Nithin Dasyam</strong>
                <br />
                Dallas, Texas, USA
                <br />
                Email: nithin.dasyam@gmail.com
                <br />
                Phone: +1 (813) 842-4187
              </address>
            </Card>
          </Col>
          <Col md={6}>
            <Card className='p-4'>
              <h4>Contact Form</h4>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='email'
                    name='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='source'>
                  <Form.Label>How did you find me?</Form.Label>
                  <Form.Control
                    as='select'
                    name='source'
                    value={formData.source}
                    onChange={handleInputChange}
                  >
                    <option>Google Search</option>
                    <option>Social Media</option>
                    <option>Word of Mouth</option>
                    <option>Other</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='comments'>
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    name='comments'
                    value={formData.comments}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Button variant='primary' type='submit'>
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection;
